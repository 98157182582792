var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-avatar",
    { attrs: { color: _vm.color, size: _vm.avatarSize } },
    [
      _vm.hasImage
        ? _c("v-img", { attrs: { src: _vm.image } })
        : _c("v-icon", { attrs: { dark: "", size: _vm.avatarIconSize } }, [
            _vm._v(" mdi-account ")
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }