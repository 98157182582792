<template>
  <v-avatar :color="color" :size="avatarSize">
    <v-img v-if="hasImage" :src="image"></v-img>
    <v-icon dark v-else :size="avatarIconSize"> mdi-account </v-icon>
  </v-avatar>
</template>
<script>
/**
 * This is the Avatar component with 3 size: small, medium and large.
 * @props {String} size - Have to be one of the following: small, medium, large.
 */
export default {
  name: "InqliAvatar",
  props: {
    size: {
      type: String,
      default: "medium",
    },
    isClickable: {
      type: Boolean,
      default: false,
    },
    image: String,
  },
  computed: {
    color() {
      return this.isClickable ? "inqliPurple" : "inqliGreen";
    },
    hasImage() {
      return this.image && this.image != "default";
    },
    avatarIconSize() {
      return this.avatarSize - 5;
    },
    avatarSize() {
      switch (this.size) {
        case "small":
          return "24";
        case "xlarge":
          return "140";
        case "large":
          return "104";
        case "medium":
          return "46";
        case "sidebar":
          return "32";
        default:
          return this.size;
      }
    },
  },
};
</script>
