var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    _vm._g(
      _vm._b({ class: _vm.buttonClasses }, "v-btn", _vm.$attrs, false),
      _vm.$listeners
    ),
    [
      _vm._t("default"),
      _vm.isProcess
        ? _c("v-progress-circular", {
            staticClass: "ml-2",
            attrs: { indeterminate: "", color: "white", size: "12", width: "2" }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }