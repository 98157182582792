<template>
  <v-btn :class="buttonClasses" v-on="$listeners" v-bind="$attrs">
    <slot></slot>
    <v-progress-circular
      v-if="isProcess"
      indeterminate
      color="white"
      size="12"
      width="2"
      class="ml-2"
    />
  </v-btn>
</template>
<script>
export default {
  name: "InqliButton",
  inheritAttrs: false,
  props: {
    isProcess: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: "",
    },
  },
  computed: {
    buttonClasses() {
      return `${this.classes} button`;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-btn.button {
  font-size: 13px;
  font-weight: 600;
  color: white;
  box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
  background-color: $inqliPurple;
}
.v-btn.button:hover {
  background-color: $inqliButtonHover;
  color: white !important;
}
.v-btn.button:focus {
  background-color: $inqliButtonFocus;
  border: none !important;
  outline: none;
}
.v-btn.button:disabled {
  color: white !important;
}
</style>
