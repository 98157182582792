<template>
  <v-btn class="button" outlined :href="link" v-on="$listeners" v-bind="$attrs">
    <slot></slot>
  </v-btn>
</template>
<script>
export default {
  name: "InqliOutlineButton",
  inheritAttrs: false,
  props: {
    isProcess: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
.v-btn.button {
  font-size: 13px;
  font-weight: 600;
  box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
}
.v-btn.button:hover {
  color: $inqliButtonHover !important;
  border-color: $inqliButtonHover;
}

.v-btn.button:focus {
  color: $inqliButtonFocus !important;
  border-color: $inqliButtonFocus !important;
  outline: none;
}
</style>
