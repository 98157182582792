import { render, staticRenderFns } from "./Avatar.vue?vue&type=template&id=5f5201e0&"
import script from "./Avatar.vue?vue&type=script&lang=js&"
export * from "./Avatar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VAvatar,VIcon,VImg})


/* hot reload */
if (module.hot) {
  var api = require("/home/matt/Documents/inqli/product/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f5201e0')) {
      api.createRecord('5f5201e0', component.options)
    } else {
      api.reload('5f5201e0', component.options)
    }
    module.hot.accept("./Avatar.vue?vue&type=template&id=5f5201e0&", function () {
      api.rerender('5f5201e0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/app/components/avatars/Avatar.vue"
export default component.exports